<template>
  <div class="entitymapstable">
    <r-error-message
      v-if="fetchError"
      class="entitymapstable--errormessage"
      :errors="[fetchError]"
    />
    <r-table
      v-if="entityMaps.length"
      class="entitymapstable--table"
      :columns="tableColumns"
      :rows="entityMaps"
      :total-rows="totalEntityMaps"
      :page="page"
      :page-size="pageSize"
      :loading="fetching"
      @change:page="({ value }) => setPage({ page: value })"
      @change:page-size="onChangePageSize"
      @change:page-size-all="onChangePageSizeAll"
      @sort:asc="({ value }) => setSort({ sort: value, sortDir: 'asc' })"
      @sort:desc="({ value }) => setSort({ sort: value, sortDir: 'desc' })"
      @change:batch-select="(data) => $emit('change:batch-select', data)"
      @change:batch-select-all="(data) => $emit('change:batch-select-all')"
    >
      <template v-slot:item.name="{ item }">
        <router-link :to="`/entitymaps/${item._id}`">
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:item.lastEdited="{ item }">
        <span v-if="item.lastEdited">{{ formatDate(item.lastEdited) }}</span>
        <span v-else>Never</span>
      </template>
      <template v-slot:item.created="{ item }">
        {{ formatDate(item.created) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="entitymapstable--btn mr-3"
          icon
          @click="$router.push(`/entitymaps/${item._id}`)"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          class="entitymapstable--btn"
          icon
          @click="onClickDelete(item)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </r-table>

    <r-modal
      v-if="confirmDelete"
      :title="$t('entityMaps.deleteEntityMap.title')"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="$t('deleteConfirmation.delete')"
      :fetching="fetching"
      @submit="onConfirmDelete"
      @close="confirmDelete = false"
    >
      <template v-slot:content>
        <r-error-message :errors="[fetchError]" />

        <div class="d-flex justify-center align-center">
          {{ $t('entityMaps.deleteEntityMap.text') }}
        </div>
      </template>
    </r-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RTable from '@/components/library/organisms/RTable'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RModal from '@/components/library/organisms/RModal'
import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'
import debounce from '@/utils/debounce'

export default {
  name: 'EntityMapsTable',
  components: {
    RTable,
    RErrorMessage,
    RModal,
  },
  props: {
    search: String,
  },
  data: () => ({
    debouncedFetchData: () => {},
    tableColumns: [
      {
        key: 'name',
        label: 'Entity map',
        hideable: true,
        sortable: true,
        width: 220,
      },
      {
        key: 'numGroups',
        label: 'Entity groups',
        hideable: true,
        sortable: true,
        width: 150,
      },
      {
        key: 'numDashboards',
        label: 'No. of dashboards',
        hideable: true,
        sortable: true,
        width: 150,
      },
      {
        key: 'lastEdited',
        label: 'Last edited',
        hideable: true,
        sortable: true,
        width: 140,
      },
      {
        key: 'created',
        label: 'Creation date',
        hideable: true,
        sortable: true,
        width: 140,
      },
      {
        key: 'actions',
        label: 'Actions',
        hideable: false,
        width: 130,
      },
    ],
    confirmDelete: false,
    deleteEntityMapId: null,
  }),
  computed: mapGetters('entityMaps', [
    'entityMaps',
    'totalEntityMaps',
    'fetching',
    'fetchError',
    'page',
    'pageSize',
    'sort',
    'sortDir',
  ]),
  watch: {
    page() {
      this.$data.debouncedFetchData()
    },
    pageSize() {
      this.$data.debouncedFetchData()
    },
    search() {
      this.setPage({ page: 1 })
      this.$data.debouncedFetchData()
    },
    sort() {
      this.$data.debouncedFetchData()
    },
    sortDir() {
      this.$data.debouncedFetchData()
    },
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
    this.fetchData()
  },
  methods: {
    ...mapActions('entityMaps', [
      'fetchEntityMaps',
      'deleteEntityMap',
      'setPage',
      'setPageSize',
      'setSort',
    ]),
    fetchData() {
      const { search } = this.$props
      this.fetchEntityMaps({ search })
    },
    formatDate(date) {
      return formatDate({
        $moment: this.$moment,
        date: dateStrToDate(date),
      })
    },
    onChangePageSize({ value }) {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: value })
    },
    onChangePageSizeAll() {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: this.totalEntityMaps })
    },
    onClickDelete({ _id }) {
      this.$data.deleteEntityMapId = _id
      this.$data.confirmDelete = true
    },
    async onConfirmDelete() {
      const { deleteEntityMapId } = this.$data
      await this.deleteEntityMap({ entityMapId: deleteEntityMapId })
      if (!this.fetchError) {
        this.$data.confirmDelete = false
      }
      this.$data.debouncedFetchData()
    },
  },
}
</script>

<style scoped>
.entitymapstable--table /deep/ .rtable--data--container .rtable--table {
  height: 512px !important;
}
.entitymapstable--table /deep/ .rtable--data--container .rtable--table .rtable--body {
  height: 452px !important;
}
/deep/ .entitymapstable--btn.v-btn--icon.v-size--default {
  width: 1.07692rem;
  height: 1.07692rem;
}
/deep/ .entitymapstable--btn.v-btn.v-btn--icon .v-icon {
  font-size: 1.23077rem;
}
</style>
